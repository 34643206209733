.App {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoom-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid #ccc;
}

.tools {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  display: flex;
  gap: 20px;
}

.tools button {
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

.reset-button {
  margin-left: 20px;
}

.floating-buttons {
  position: fixed;
  bottom: 80px; /* Adjust to leave space for footer */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.floating-upload-button,
.floating-download-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: none;
}

.floating-upload-button input[type="file"] {
  display: none;
}

.footer {
  position: fixed;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #777;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  cursor: grab;
  z-index: 1;
}

.overlay-image {
  display: block;
  width: 100%;
  height: auto;
}

.background-image {
  display: block;
  width: 100%;
  height: auto;
  cursor: grab;
}
